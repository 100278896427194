import React from 'react'
import PropTypes from 'prop-types'
// import {Link, StaticQuery, graphql} from 'gatsby'
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import {HTMLContent} from './Content'
import {remark} from 'remark'
import remarkHtml from 'remark-html'
import NewsPreview from './NewsPreview'
// import accent from '../img/accent2.svg'
import arrow from '../img/arrow.svg'
import NavbarTransparent from './NavbarTransparent'
import Footer from './Footer'
import Helmet from 'react-helmet'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'
import "typeface-ubuntu"
import "./all.sass"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHome } from '@fortawesome/free-solid-svg-icons'

import ImageBar from './ImageBar'
  
const HomePageTemplate = ({
  welcomeMessage,
  welcomeAside,
}) => (
  < >
    <Helmet title="Encanto Real Utility District" />
    <div className="">
    <NavbarTransparent />
    <section className="section__home-page">
      <ImageBar />
      <div className="container">
        <div className="columns accent-offset ">
          <div className="column is-3">
            <Link to="/latest-updates" className="blog-button" style={{ float: 'right', marginBottom: '2rem' }}>
                <img src={arrow} alt="" /><br />
                SEE ALL<br />
                LATEST UPDATES
              </Link>
              <div className="card" style={{ marginTop: '2rem', padding: '1rem', clear: 'right'}}>
                <h3 className="is-size-4" style={{ marginBottom: '1rem'}}>Signup to receive updates!</h3>
                <MailchimpSubscribe
                  url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=85a9782a5e"
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
            </div>
          </div>
          <div className="column is-9">
            <NewsPreview />
          </div>
        </div>
      </div>
    </section>
    </div>
    <section className="section">
      <div className="container">
      <div className="columns">
        <div className="column is-6 is-offset-1 ">
          <div className="content">
            <HTMLContent content={remark().use(remarkHtml).processSync(welcomeMessage).toString()}/>
            <hr />
            <p>The annual financial report for June 30, 2021 is now available.</p>
            <p><a href="https://www.encantorealud.com/static/fb574822ce7ef5b830f4436b7f68d72a/Audit_2021_Encanto_4875_7886_7715_1_dd87679168.pdf" target="_blank" rel="noopener noreferrer" class="button is-secondary">View Report</a></p>
          </div>
        </div>
        {/* <div className="column is-3 is-offset-1 content has-text-centered">
          <h3 style={{display: 'block', borderBottom: 'solid 2px rgba(0,0,0,0.1)', marginBottom: '1.5rem'}}>Stage 1 Water Shortage</h3>
          <p><strong>Voluntary Irrigation Restrictions</strong><br />8 p.m. &ndash; 6 a.m.</p>
          <p><strong>Even Address & Common Areas</strong><br />Even Numbered Dates</p>
          <p><strong>Odd Address</strong><br />Odd Numbered Dates</p>
          <p>281-376-8802</p>
          <a className="button is-primary is-block" href="https://www.encantorealud.com/static/36f594b4895fd2f5bf757c70e6af366c/Drought_Contingency_Plan_Encanto_2013_db24051987.pdf" target="_blank" rel="noopener noreferrer">Drought Contingency Plan</a>
          <Image style={{width: '100%', height: 'auto', borderRadius: '4px', marginTop: '1rem'}} fluid={welcomeAside.childImageSharp.fluid} alt="" />
        </div> */}
      </div>
      </div>
    </section>
    <Footer />
  </ >
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
